import React, { useState, useEffect, useRef, useContext } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import AuthContext from "../context/AuthContext";

// Modal component for add inventory input
function InventoryModal({ isOpen, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    subCategories: [
      {
        name: "",
        quantity: 0,
        price: 0,
        uniqueId: "",
        type: "",
        measurement: "unit",
        location: "",
      },
    ],
    SKU: "",
    images: [""],
    company: "",
  });

  const modalRef = useRef(); // Create a ref for the modal content

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Close modal on backdrop click
  const handleBackdropClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside of the modal
    document.addEventListener("mousedown", handleBackdropClick);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleBackdropClick);
    };
  }, []);

  if (!isOpen) return null;

  const handleSubCategoryChange = (index, e) => {
    const { name, value } = e.target;
    const newSubCategories = [...formData.subCategories];
    newSubCategories[index][name] = value;
    setFormData({
      ...formData,
      subCategories: newSubCategories,
    });
  };

  const addSubCategory = () => {
    setFormData({
      ...formData,
      subCategories: [
        ...formData.subCategories,
        {
          name: "",
          quantity: 0,
          price: 0,
          uniqueId: "",
          type: "",
          measurement: "unit", // Default measurement unit
          location: "",
        },
      ],
    });
  };

  const toggleMeasurement = (index) => {
    const newSubCategories = [...formData.subCategories];
    newSubCategories[index].measurement =
      newSubCategories[index].measurement === "unit" ? "mL" : "unit"; // Toggle measurement
    setFormData({
      ...formData,
      subCategories: newSubCategories,
    });
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  if (!isOpen) return null;

  // Inline styles for the modal
  const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "400px", // Set the width of the modal
    maxHeight: "80vh", // Maximum height for the modal
    overflowY: "hidden", // Hide the scrollbar
    display: "flex",
    flexDirection: "column", // Make sure content is displayed in a column
  };

  // Optional: If you want to keep the content accessible by scrolling without showing the scrollbar
  const scrollContainerStyle = {
    overflowY: "scroll", // Allow scrolling without showing the scrollbar
    flexGrow: 1, // Allow the container to grow
    paddingRight: "10px", // Add padding to avoid content sticking to the edge
    scrollbarWidth: "none", // Firefox (hides scrollbar)
    "-ms-overflow-style": "none", // Internet Explorer and Edge
  };

  const inputStyle = {
    width: "95%",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  const labelStyle = {
    display: "block",
    margin: "5px 0",
    fontWeight: "bold",
  };

  const toggleButtonStyle = {
    padding: "5px 10px",
    margin: "10px 0",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#4CAF50",
    color: "white",
    cursor: "pointer",
    textAlign: "center",
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle} ref={modalRef}>
        <h2>Add Inventory</h2>
        <div style={scrollContainerStyle}>
          <form>
            <label style={labelStyle}>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter item name"
              style={inputStyle}
            />

            <label style={labelStyle}>Description</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter item description"
              style={inputStyle}
            />

            <label style={labelStyle}>Category</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              placeholder="Enter category"
              style={inputStyle}
            />

            {formData.subCategories.map((subCategory, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <h4>Subcategory {index + 1}</h4>

                <label style={labelStyle}>Subcategory Name</label>
                <input
                  type="text"
                  name="name"
                  value={subCategory.name}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  placeholder="Enter subcategory name"
                  style={inputStyle}
                />

                <label style={labelStyle}>Quantity</label>
                <input
                  type="number"
                  name="quantity"
                  value={subCategory.quantity}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  placeholder="Enter quantity"
                  style={inputStyle}
                />

                <label style={labelStyle}>Price</label>
                <input
                  type="number"
                  name="price"
                  value={subCategory.price}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  placeholder="Enter price"
                  style={inputStyle}
                />

                <label style={labelStyle}>Unique ID</label>
                <input
                  type="text"
                  name="uniqueId"
                  value={subCategory.uniqueId}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  placeholder="Enter unique ID"
                  style={inputStyle}
                />

                <label style={labelStyle}>Item Type</label>
                <select
                  name="type"
                  value={subCategory.type}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  style={inputStyle}
                >
                  <option value="" disabled>
                    Select type
                  </option>{" "}
                  {/* Placeholder */}
                  <option value="solid">Solid</option>
                  <option value="liquid">Liquid</option>
                </select>

                <label style={labelStyle}>Measurement</label>
                <div>
                  <span style={{ marginRight: "10px" }}>
                    {subCategory.measurement}
                  </span>
                  <button
                    type="button"
                    onClick={() => toggleMeasurement(index)}
                    style={toggleButtonStyle}
                  >
                    Toggle
                  </button>
                </div>

                <label style={labelStyle}>Location</label>
                <input
                  type="text"
                  name="location"
                  value={subCategory.location}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  placeholder="Enter location"
                  style={inputStyle}
                />
              </div>
            ))}

            <button
              type="button"
              onClick={addSubCategory}
              style={toggleButtonStyle}
            >
              Add Subcategory
            </button>

            <label style={labelStyle}>SKU</label>
            <input
              type="text"
              name="SKU"
              value={formData.SKU}
              onChange={handleInputChange}
              placeholder="Enter SKU"
              style={inputStyle}
            />

            <label style={labelStyle}>Image URL</label>
            <input
              type="text"
              name="images"
              value={formData.images[0]}
              onChange={(e) => {
                const newImages = [...formData.images];
                newImages[0] = e.target.value; // Assuming single image for simplicity
                setFormData({ ...formData, images: newImages });
              }}
              placeholder="Enter image URL"
              style={inputStyle}
            />

            <label style={labelStyle}>Company</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              placeholder="Enter company name"
              style={inputStyle}
            />

            <button
              type="button"
              onClick={handleSubmit}
              style={toggleButtonStyle}
            >
              Submit
            </button>
          </form>
        </div>
        <button
          onClick={onClose}
          style={{ ...toggleButtonStyle, backgroundColor: "#f44336" }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

// Stock transfer modal
function TransferModal({
  isOpen,
  onClose,
  subCategories,
  selectedSubCategory,
  onSubmit,
  setSelectedSubCategory,
  uniqueIds, // Add this to pass unique IDs of items being transferred
}) {
  const [formData, setFormData] = useState({
    subCategoryName: "",
    newLocation: "",
    uniqueIds: [], // Store unique IDs here
    transferQuantity: 0,
  });

  useEffect(() => {
    if (subCategories.length > 0) {
      setFormData({
        subCategoryName: subCategories[0].name, // Default to the first sub-category
        newLocation: subCategories[0].location,
        uniqueIds: [], // Reset unique IDs
        transferQuantity: 0, // Reset transfer quantity
      });
    }
  }, [subCategories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    // Before submitting, ensure uniqueIds are properly updated
    const uniqueIds = selectedSubCategory.units
      .slice(0, formData.transferQuantity)
      .map((unit) => unit.uniqueId); // Ensure the selected units match the transfer quantity

    // Add the uniqueIds to the form data before submitting
    onSubmit({ ...formData, uniqueIds });
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal"
      style={{
        display: "flex",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 1000,
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: "#f9f9f9",
          padding: "30px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "500px",
          maxWidth: "90%",
        }}
      >
        <h2 style={{ color: "#333", fontSize: "22px", marginBottom: "20px" }}>
          Transfer Stock (Possible between same items)
        </h2>

        <label style={{ display: "block", marginBottom: "15px" }}>
          <span
            style={{
              fontSize: "14px",
              color: "#333",
              display: "block",
              marginBottom: "5px",
            }}
          >
            Sub-Category:
          </span>
          <select
            name="subCategoryName"
            value={formData.subCategoryName}
            onChange={(e) => {
              const selectedName = e.target.value;
              const selected = subCategories.find(
                (sub) => sub.name === selectedName
              );
              setSelectedSubCategory(selected); // Update the selected sub-category
              handleInputChange(e); // Update form data
            }}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              color: "#555",
              fontSize: "14px",
            }}
          >
            {subCategories.map((sub) => (
              <option key={sub.name} value={sub.name}>
                {sub.name}
              </option>
            ))}
          </select>
        </label>

        <label style={{ display: "block", marginBottom: "15px" }}>
          <span
            style={{
              fontSize: "14px",
              color: "#333",
              display: "block",
              marginBottom: "5px",
            }}
          >
            New Location:
          </span>
          <select
            name="newLocation"
            value={formData.newLocation}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              color: "#555",
              fontSize: "14px",
            }}
          >
            {subCategories.map((sub) => (
              <option key={sub.location} value={sub.location}>
                {sub.location}
              </option>
            ))}
          </select>
        </label>

        <label style={{ display: "block", marginBottom: "20px" }}>
          <span>
            Transfer Quantity (Max:{" "}
            {selectedSubCategory?.availableQuantity || 0}):
          </span>
          <input
            type="number"
            name="transferQuantity"
            value={formData.transferQuantity}
            onChange={(e) => {
              const quantity = Number(e.target.value);
              if (quantity <= selectedSubCategory.availableQuantity) {
                handleInputChange(e); // Allow change if it's valid
              } else {
                alert(
                  `Cannot transfer more than ${selectedSubCategory.availableQuantity}`
                );
              }
            }}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              color: "#555",
              fontSize: "14px",
            }}
          />
        </label>

        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <button
            onClick={handleSubmit}
            style={{
              padding: "10px 20px",
              backgroundColor: "#28a745",
              color: "#fff",
              border: "none",
              borderRadius: "6px",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Submit
          </button>
          <button
            onClick={onClose}
            style={{
              padding: "10px 20px",
              backgroundColor: "#dc3545",
              color: "#fff",
              border: "none",
              borderRadius: "6px",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

const EditInventoryModal = ({ item, setEditModalOpen, fetchInventory }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    subCategories: [],
    SKU: "",
    images: [""],
    company: "",
  });

  const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "400px", // Set the width of the modal
    maxHeight: "80vh", // Maximum height for the modal
    overflowY: "hidden", // Hide the scrollbar
    display: "flex",
    flexDirection: "column", // Make sure content is displayed in a column
  };
  const scrollContainerStyle = {
    overflowY: "scroll", // Allow scrolling without showing the scrollbar
    flexGrow: 1, // Allow the container to grow
    paddingRight: "10px", // Add padding to avoid content sticking to the edge
    scrollbarWidth: "none", // Firefox (hides scrollbar)
    "-ms-overflow-style": "none", // Internet Explorer and Edge
  };

  const inputStyle = {
    width: "95%",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  const labelStyle = {
    display: "block",
    margin: "5px 0",
    fontWeight: "bold",
  };
  const toggleButtonStyle = {
    padding: "5px 10px",
    margin: "10px 0",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#4CAF50",
    color: "white",
    cursor: "pointer",
    textAlign: "center",
  };

  // Populate form data when the item changes
  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name,
        description: item.description,
        category: item.category,
        subCategories: item.subCategories,
        SKU: item.SKU,
        images: item.images,
        company: item.company,
      });
    }
  }, [item]);

  const handleEditSubCategoryChange = (index, field, value) => {
    const updatedSubCategories = [...formData.subCategories];
    updatedSubCategories[index][field] = value;
    setFormData({ ...formData, subCategories: updatedSubCategories });
  };

  const addEditSubCategory = () => {
    setFormData({
      ...formData,
      subCategories: [
        ...formData.subCategories,
        {
          name: "",
          quantity: 0,
          price: 0,
          uniqueId: "",
          type: "",
          measurement: "unit",
          location: "",
        },
      ],
    });
  };

  const toggleEditMeasurement = (index) => {
    const updatedSubCategories = [...formData.subCategories];
    updatedSubCategories[index].measurement =
      updatedSubCategories[index].measurement === "unit" ? "mL" : "unit";
    setFormData({ ...formData, subCategories: updatedSubCategories });
  };

  const handleEditSubmit = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    fetch(`https://api.mrmechanic.co/api/inventory/${item._id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setEditModalOpen(false); // Close modal
        fetchInventory(); // Reload the inventory list
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <h2>Edit Inventory</h2>
        <div style={scrollContainerStyle}>
          <form>
            <label style={labelStyle}>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              style={inputStyle}
            />

            <label style={labelStyle}>Description</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              style={inputStyle}
            />

            <label style={labelStyle}>Category</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={(e) =>
                setFormData({ ...formData, category: e.target.value })
              }
              style={inputStyle}
            />

            {formData.subCategories.map((subCategory, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <h4>Subcategory {index + 1}</h4>

                <label style={labelStyle}>Subcategory Name</label>
                <input
                  type="text"
                  value={subCategory.name}
                  onChange={(e) =>
                    handleEditSubCategoryChange(index, "name", e.target.value)
                  }
                  style={inputStyle}
                />

                <label style={labelStyle}>Quantity</label>
                <input
                  type="number"
                  value={subCategory.quantity}
                  onChange={(e) =>
                    handleEditSubCategoryChange(
                      index,
                      "quantity",
                      e.target.value
                    )
                  }
                  style={inputStyle}
                />

                <label style={labelStyle}>Price</label>
                <input
                  type="number"
                  value={subCategory.price}
                  onChange={(e) =>
                    handleEditSubCategoryChange(index, "price", e.target.value)
                  }
                  style={inputStyle}
                />

                <label style={labelStyle}>Unique ID</label>
                <input
                  type="text"
                  value={subCategory.uniqueId}
                  onChange={(e) =>
                    handleEditSubCategoryChange(
                      index,
                      "uniqueId",
                      e.target.value
                    )
                  }
                  style={inputStyle}
                />

                <label style={labelStyle}>Item Type</label>
                <select
                  value={subCategory.type}
                  onChange={(e) =>
                    handleEditSubCategoryChange(index, "type", e.target.value)
                  }
                  style={inputStyle}
                >
                  <option value="" disabled>
                    Select type
                  </option>
                  <option value="solid">Solid</option>
                  <option value="liquid">Liquid</option>
                </select>

                <label style={labelStyle}>Measurement</label>
                <div>
                  <span style={{ marginRight: "10px" }}>
                    {subCategory.measurement}
                  </span>
                  <button
                    type="button"
                    onClick={() => toggleEditMeasurement(index)}
                    style={toggleButtonStyle}
                  >
                    Toggle
                  </button>
                </div>

                <label style={labelStyle}>Location</label>
                <input
                  type="text"
                  value={subCategory.location}
                  onChange={(e) =>
                    handleEditSubCategoryChange(
                      index,
                      "location",
                      e.target.value
                    )
                  }
                  style={inputStyle}
                />
              </div>
            ))}

            <button
              type="button"
              onClick={addEditSubCategory}
              style={toggleButtonStyle}
            >
              Add Subcategory
            </button>

            <label style={labelStyle}>SKU</label>
            <input
              type="text"
              name="SKU"
              value={formData.SKU}
              onChange={(e) =>
                setFormData({ ...formData, SKU: e.target.value })
              }
              style={inputStyle}
            />

            <label style={labelStyle}>Image URL</label>
            <input
              type="text"
              name="images"
              value={formData.images[0]}
              onChange={(e) => {
                const newImages = [...formData.images];
                newImages[0] = e.target.value; // Assuming single image for simplicity
                setFormData({ ...formData, images: newImages });
              }}
              style={inputStyle}
            />

            <label style={labelStyle}>Company</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={(e) =>
                setFormData({ ...formData, company: e.target.value })
              }
              style={inputStyle}
            />

            <button
              type="button"
              onClick={handleEditSubmit}
              style={toggleButtonStyle}
            >
              Submit
            </button>
          </form>
        </div>
        <button
          onClick={() => setEditModalOpen(false)}
          style={{ ...toggleButtonStyle, backgroundColor: "#f44336" }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Table for Inventory
function Inventory() {
  const { user } = useContext(AuthContext);
  const [inventoryList, setInventoryList] = useState([]);
  //   add inventory modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  //   edit inventory modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  //   stock transfer modal
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);

  const [selectedItem, setSelectedItem] = useState(null); // Add this line

  const openEditModal = (item) => {
    setSelectedItem(item); // This will now work since setSelectedItem is defined
    setIsEditModalOpen(true); // Open the modal
  };

  // Fetch the inventory list (GET request)
  const fetchInventory = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setError("No token found. Please log in.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://api.mrmechanic.co/api/inventory", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setInventoryList(result);
        setError(null);
      })
      .catch((error) => setError("Error fetching inventory."));
  };

  //   Add inventory api call
  const handleFormSubmit = (data) => {
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data), // Use form data collected from the modal
      redirect: "follow",
    };

    fetch("https://api.mrmechanic.co/api/inventory", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setIsModalOpen(false); // Close the modal after successful submission
        fetchInventory(); // Silently refresh the table
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  //   Stock transfer api call
  const handleTransferSubmit = (data) => {
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    const transferQuantity = Number(data.transferQuantity); // Ensure transferQuantity is a valid number

    if (isNaN(transferQuantity) || transferQuantity <= 0) {
      alert("Please enter a valid transfer quantity greater than 0.");
      return;
    }

    // Ensure uniqueIds matches the transferQuantity
    const uniqueIds = selectedSubCategory.units
      .slice(0, transferQuantity) // Slice the units based on transferQuantity
      .map((unit) => unit.uniqueId); // Get unique IDs for the units to transfer

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        subCategoryName: data.subCategoryName,
        newLocation: data.newLocation,
        transferQuantity: transferQuantity, // Ensure transferQuantity is sent
        uniqueIds, // Add uniqueIds to the request body
      }),
      redirect: "follow",
    };

    fetch(
      `https://api.mrmechanic.co/api/inventory/transfer/${selectedSubCategory.parentId}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(error.message);
          });
        }
        return response.json();
      })
      .then((result) => {
        setIsTransferModalOpen(false);
        fetchInventory(); // Refresh inventory after transfer
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  // Filter inventory based on search term
  const filteredInventory = inventoryList.filter((item) => {
    const { name, company } = item;

    // Check if any of the fields include the search term (case-insensitive)
    return (
      name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInventory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredInventory.length / itemsPerPage);

  const dashboardStyle = {
    display: "flex",
    flexDirection: "row",
    height: "100vh", // Ensure full height
  };
  const navbarStyle = {
    width: "100%", // Sidebar occupies 20% of the screen
    position: "fixed", // Keep it fixed
    borderRight: "1px solid #ddd", // Optional border for separation
  };

  const sidebarStyle = {
    width: "20%", // Sidebar occupies 20% of the screen
    position: "fixed", // Keep it fixed
    height: "100vh", // Full height
    borderRight: "1px solid #ddd", // Optional border for separation
    marginTop: "60px",
  };

  const contentStyle = {
    marginTop: "2%",
    marginLeft: "20%", // Leave space for the sidebar
    padding: "20px",
    flex: 1,
    height: "calc(100vh - 60px)", // Ensure full height minus the navbar height
    overflow: "hidden", // No vertical scrolling for the content wrapper
  };

  const tableContainerStyle = {
    maxHeight: "400px",
    overflow: "auto", // Enable scrolling only when necessary
    border: "1px solid #ddd",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  };
  const thStyle = {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px",
    textAlign: "left",
  };

  const tdStyle = {
    padding: "10px",
    border: "1px solid #ddd",
  };

  return (
    <div>
      <div style={navbarStyle}>
        <Navbar />
      </div>
      <div style={dashboardStyle}>
        <div style={sidebarStyle}>
          <Sidebar />
        </div>
        <div style={contentStyle}>
          <h1>Inventory</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <input
              type="text"
              placeholder="Search by name, company"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                padding: "10px",
                width: "300px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
            {user && user.role === "superadmin" && (
              <button
                onClick={() => setIsModalOpen(true)}
                style={{
                  padding: "10px 20px",
                  marginLeft: "20px",
                  backgroundColor: "#4CAF50", // Green background
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#45a049")
                } // Darker green on hover
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#4CAF50")
                } // Reset color
              >
                Add Inventory
              </button>
            )}
          </div>

          {error && <p style={{ color: "red" }}>{error}</p>}
          <div style={tableContainerStyle}>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Name</th>
                  <th style={thStyle}>Description</th>
                  <th style={thStyle}>Category</th>
                  <th style={thStyle}>Item Name</th>
                  <th style={thStyle}>Item Location</th>
                  {/* <th style={thStyle}>Item Unique Id</th> */}
                  <th style={thStyle}>Item Price</th>
                  <th style={thStyle}>Item Quantity</th>
                  <th style={thStyle}>Item Type</th>
                  <th style={thStyle}>Item Measure In</th>
                  <th style={thStyle}>SKU</th>
                  {/* <th style={thStyle}>Images</th> */}
                  <th style={thStyle}>Company</th>
                  <th style={thStyle}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) =>
                  item.subCategories.map((subCategory) => (
                    <tr key={subCategory._id}>
                      {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.name}
                        </td>
                      ) : null}
                      {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.description}
                        </td>
                      ) : null}
                      {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.category}
                        </td>
                      ) : null}
                      <td style={tdStyle}>{subCategory.name}</td>
                      <td style={tdStyle}>{subCategory.location}</td>
                      {/* <td style={tdStyle}>{subCategory.uniqueId}</td> */}
                      <td style={tdStyle}>{subCategory.price}</td>
                      <td style={tdStyle}>{subCategory.quantity}</td>
                      <td style={tdStyle}>{subCategory.type}</td>
                      <td style={tdStyle}>{subCategory.unit}</td>
                      {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.SKU}
                        </td>
                      ) : null}
                      {/* {subCategory === item.subCategories[0] ? (
                      <td rowSpan={item.subCategories.length} style={tdStyle}>
                        {item.images}
                      </td>
                    ) : null} */}
                      {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.company}
                        </td>
                      ) : null}
                      {subCategory === item.subCategories[0] ? (
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <button
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "#007BFF",
                              color: "white",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginRight: "10px",
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                            onClick={() => openEditModal(item)} // Open the modal with current data
                          >
                            Edit
                          </button>

                          {user && user.role === "superadmin" && (
                            <button
                              style={{
                                padding: "5px 10px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                                display: "inline-block",
                                verticalAlign: "middle",
                              }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this inventory?"
                                  )
                                ) {
                                  const token = localStorage.getItem("token");

                                  if (!token) {
                                    console.error(
                                      "No token found. Please log in."
                                    );
                                    return;
                                  }

                                  const myHeaders = new Headers();
                                  myHeaders.append("x-auth-token", token);

                                  const requestOptions = {
                                    method: "DELETE",
                                    headers: myHeaders,
                                    redirect: "follow",
                                  };

                                  fetch(
                                    `https://api.mrmechanic.co/api/inventory/${item._id}`,
                                    requestOptions
                                  )
                                    .then((response) => {
                                      if (!response.ok) {
                                        throw new Error(
                                          `Error: ${response.status} ${response.statusText}`
                                        );
                                      }
                                      return response.text();
                                    })
                                    .then((result) => {
                                      console.log(result);
                                      // Optionally, refresh the inventory list after successful deletion
                                      fetchInventory();
                                    })
                                    .catch((error) =>
                                      console.error("Error:", error)
                                    );
                                }
                              }}
                            >
                              Delete
                            </button>
                          )}

                          {/* Stock transfer */}
                          <button
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "gray",
                              color: "white",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() => {
                              const subCategoryWithQuantity = {
                                ...subCategory, // This assumes subCategory has an object structure that you need
                                parentId: item._id, // Store the parent category's _id
                                availableQuantity: subCategory.quantity, // Ensure you have the available quantity
                              };

                              setSelectedSubCategory(subCategoryWithQuantity); // Set the selected subcategory
                              setSelectedCategory(item); // Set the selected category here if needed
                              setIsTransferModalOpen(true); // Open the transfer modal
                            }}
                          >
                            Transfer
                          </button>
                        </td>
                      ) : null}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "20px" }}>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              style={{ margin: "0 10px" }}
            >
              Previous
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
          {/* add Inventory modal */}
          <InventoryModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleFormSubmit}
          />
          {isEditModalOpen && (
            <EditInventoryModal
              item={selectedItem}
              setEditModalOpen={setIsEditModalOpen} // Changed setIsModalOpen to setIsEditModalOpen
              fetchInventory={fetchInventory}
            />
          )}

          {/* Stock transfer modal */}
          {isTransferModalOpen && (
            <TransferModal
              isOpen={isTransferModalOpen}
              onClose={() => setIsTransferModalOpen(false)}
              subCategories={selectedCategory?.subCategories || []}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory} // Pass it as a prop
              onSubmit={handleTransferSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Inventory;
