import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

function PartnerList() {
  const [partners, setPartners] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [partnersPerPage] = useState(5); // Number of partners per page
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPartners = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("No token found. Please log in.");
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("x-auth-token", token);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "https://api.mrmechanic.co/api/auth/partner",
          requestOptions
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();
        setPartners(result);
        setFilteredPartners(result); // Initialize with full list
      } catch (error) {
        setError(error.message);
      }
    };

    fetchPartners();
  }, []);

  useEffect(() => {
    const filtered = partners.filter(
      (partner) =>
        partner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        partner.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        partner.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (partner.deleted ? "deleted" : "active")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredPartners(filtered);
  }, [searchTerm, partners]);

  // Pagination logic
  const indexOfLastPartner = currentPage * partnersPerPage;
  const indexOfFirstPartner = indexOfLastPartner - partnersPerPage;
  const currentPartners = filteredPartners.slice(
    indexOfFirstPartner,
    indexOfLastPartner
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const dashboardStyle = {
    display: "flex",
    flexDirection: "row",
  };

  const contentStyle = {
    marginLeft: "300px",
    padding: "20px",
    flex: 1,
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  };

  const thStyle = {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px",
    textAlign: "left",
  };

  const tdStyle = {
    padding: "10px",
    border: "1px solid #ddd",
  };

  const inputStyle = {
    padding: "10px",
    marginBottom: "20px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "16px",
  };

  const paginationStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  };

  const paginationButtonStyle = {
    padding: "10px 15px",
    margin: "0 5px",
    cursor: "pointer",
    backgroundColor: "#f4f4f4",
    border: "1px solid #ddd",
    borderRadius: "5px",
  };

  return (
    <div>
      <Navbar />
      <div style={dashboardStyle}>
        <Sidebar />
        <div style={contentStyle}>
          <h2>Partner's List</h2>

          {/* Search Input */}
          <input
            style={inputStyle}
            type="text"
            placeholder="Search by name, email, company, or status"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {/* Partners Table */}
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>Name</th>
                <th style={thStyle}>Email</th>
                <th style={thStyle}>Company</th>
                <th style={thStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentPartners.map((partner) => (
                <tr key={partner._id}>
                  <td style={tdStyle}>{partner.name}</td>
                  <td style={tdStyle}>{partner.email}</td>
                  <td style={tdStyle}>{partner.company}</td>
                  <td style={tdStyle}>
                    {partner.deleted ? "Deleted" : "Active"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div style={paginationStyle}>
            {Array.from(
              { length: Math.ceil(filteredPartners.length / partnersPerPage) },
              (_, index) => (
                <button
                  key={index}
                  style={paginationButtonStyle}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerList;
