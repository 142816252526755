import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["x-auth-token"] = token;
      fetchUser();
    }
  }, [token]);

  const login = async (email, password) => {
    try {
      const res = await axios.post("https://api.mrmechanic.co/api/auth/login", {
        email,
        password,
      });
      const newToken = res.data.token;
      setToken(newToken);
      localStorage.setItem("token", newToken);

      // Set the token in Axios headers
      axios.defaults.headers.common["x-auth-token"] = newToken;

      // Fetch user after setting token
      const user = await fetchUser();
      if (user) {
        navigate("/dashboard");
      }
    } catch (err) {
      console.error("Login error", err.response?.data?.message || err.message);
    }
  };

  const register = async (name, email, password, role, company) => {
    try {
      const res = await axios.post("https://api.mrmechanic.co/api/auth/register", {
        name,
        email,
        password,
        role,
        company,
      });
      // Remove the auto-login logic here
      return res.data; // Optionally return the response data
    } catch (err) {
      console.error("Registration error", err.response.data.message);
      throw err; // Propagate the error for further handling if needed
    }
  };

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("token"); // Get the token
      console.log("Using token:", token); // Log token for debugging
      const res = await axios.get("https://api.mrmechanic.co/api/auth/profile");
      console.log("Fetched user data:", res.data);
      setUser(res.data);
      return res.data;
    } catch (err) {
      console.error("Fetch user error:", err);
      logout();
      return null;
    }
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["x-auth-token"];
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ user, token, login, register, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
